<template>
  <div>
    <b-form @submit.prevent="handleSubmitCredentials()">
      <b-form-group label="Seguradora">
        <b-form-input
          required
          placeholder="Digite o CNPJ da seguradora"
          :class="$v.form.insurance.$error && 'is-invalid'"
          v-mask="cnpjNumberMask"
          @change="handleGetCnpj()"
          v-model="$v.form.insurance.$model"
          @keydown.enter.prevent=""
        />
      </b-form-group>
      <div v-if="insurance_data">
        <strong class="d-block mb-2">{{ insurance_data.social_reason }}</strong>
      </div>
      <hr />
      <section v-if="insurance_data && !invalid_cnpj">
        <b-form-group label="Tipo Seguro">
          <multiselect
            required
            searchable
            close-on-select
            :show-labels="false"
            :internal-search="false"
            placeholder="Selecione um Tipo"
            label="name"
            v-model="$v.form.type.$model"
            :options="typeOptions"
          />
          <span class="force-invalid-feedback" v-if="$v.form.type.$error">
            O tipo de seguro é obrigatório
          </span>
        </b-form-group>
        <b-form-group label="Apólice">
          <b-form-input
            id="police-input-data"
            placeholder="SSSSS-AAAA-FFFF-RRRR-NNNN-EEEEE"
            autocomplete="off"
            v-mask="'#####-####-####-####-####-#####'"
            v-model="$v.form.policy_num.$model"
            :class="$v.form.policy_num.$error && 'is-invalid'"
            trim
            @keydown.enter.prevent=""
          >
            <b-form-invalid-feedback v-if="!$v.form.policy_num.required">
              Adicione o número da apólice
            </b-form-invalid-feedback>
          </b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.policy_num.required">
            A apólice é obrigatória
          </b-form-invalid-feedback>
          <b-popover
            target="police-input-data"
            placement="left"
            title="Sequência numérica"
            triggers="hover"
          >
            <div v-for="(input, index) in inputs" :key="index">
              <span class="d-block mt-2">{{ input.placeholder }}</span>
              <span>{{ input.title }}</span>
              <hr />
            </div>
          </b-popover>
        </b-form-group>

        <b-form-group label="Cobertura">
          <b-form-input
            required
            v-money.lazy="maskMoney"
            v-model="$v.form.coverage.$model"
            :class="$v.form.coverage.$error && 'is-invalid'"
            placeholder="R$ 00,00"
            @keydown.enter.prevent=""
          />
          <b-form-invalid-feedback v-if="!$v.form.coverage.required">
            Adicione a cobertura
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Vigência">
          <b-form-datepicker
            placeholder="00/00/0000"
            required
            :date-format-options="dateFormat"
            v-model="$v.form.validity.$model"
            :class="$v.form.validity.$error && 'is-invalid'"
            class="mb-2"
          />

          <b-form-invalid-feedback v-if="!$v.form.validity.required">
            Adicione a vigência
          </b-form-invalid-feedback>
        </b-form-group>

        <section v-if="form.files">
          <div
            class="d-flex align-items-center"
            v-for="(file, index) in form.files"
            :key="index"
          >
            <span> {{ file.name }}</span>
            <b-icon icon="x" class="pointer" @click="handleDeleteFile(index)" />
          </div>
        </section>
        <b-button
          v-else
          @click="handleAddEvidence()"
          variant="link"
          class="attachment pl-0"
        >
          <b-icon icon="paperclip" class="mr-1" />
          <span>Anexar comprovante</span>
        </b-button>

        <div class="d-flex justify-content-end">
          <b-button variant="link" @click="$emit('submited')">
            Cancelar
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            :disabled="isLoading"
            class="pull-right text-right"
          >
            <b-spinner v-if="isLoading" small class="mr-2" />
            <span> {{ $t("general.save") }}</span>
          </b-button>
        </div>
      </section>
      <b-modal
        id="add-file-insurance"
        size="md"
        hide-footer
        centered
        title="Adicionar comprovante do seguro"
      >
        <file-upload @upload="handleUploadFileInsurance" />
        <template #modal-footer="{ close }">
          <b-button variant="link" @click="close()">Cancelar</b-button>
          <b-button variant="primary"> Salvar </b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
 
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import FileUpload from "@/components/FileUpload";
import { api } from "@/services/api";
import { getCnpj } from "@/utils/validations/getCnpj";

export default {
  components: {
    FileUpload,
  },
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
    isAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      cnpjNumberMask: "##.###.###/####-##",
      isLoading: false,
      loadingCnpj: false,
      invalid_cnpj: false,
      insurance_data: null,
      dateFormat: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      maskMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },
      inputs: [
        {
          title: "Código SUSEP, de 5 dígitos, da sociedade seguradora.",
          value: "",
          placeholder: "SSSSS",
          mask: "#####",
        },
        {
          title: "Ano de emissão da apólice.",
          value: "",
          placeholder: "AAAA",
          mask: "####",
        },
        {
          title:
            "Identificador, de 4 dígitos, da sucursal da emissão da apólice.",
          value: "",
          placeholder: "FFFF",
          mask: "####",
        },
        {
          title: "Código SUSEP, de 4 dígitos, do ramo da operação.",
          value: "",
          placeholder: "RRRR",
          mask: "####",
        },
        {
          title: "Número sequencial, por ramo de operação.",
          value: "",
          placeholder: "NNNN",
          mask: "####",
        },
        {
          title:
            "Número sequencial do endosso, dentro da apólice a que está vinculado. No caso de apólice, a seguradora pode ter preenchido com espaços ou zeros.",
          value: "",
          placeholder: "EEEEEE",
          mask: "#####",
        },
      ],
      typeOptions: [],
      form: {
        policy_num: null,
        insurance: null,
        coverage: null,
        validity: null,
        files: null,
        type: null,
      },
    };
  },
  validations: {
    form: {
      policy_num: {
        required,
        minLength: minLength(4),
      },
      insurance: {
        required,
      },
      validity: {
        required,
      },
      coverage: {
        required,
      },
      files: {
        required,
      },
      type: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user"]),
  },
  methods: {
    ...mapActions([
      "add_insurance_provider",
      "get_insurance_provider",
      "show_toast",
      "edit_insurance_provider",
      "get_all_type"
    ]),
    handleGetCnpj() {
      this.loadingCnpj = true;
      const cnpj = this.form.insurance.replace(/[^\d]/g, "");
      getCnpj(cnpj)
        .then((data) => {
          if (data.status !== "ERROR") {
            this.invalid_cnpj = false;
            this.insurance_data = {
              ...data,
              social_reason: data.fantasia,
              activity: data.atividade_principal[0].text,
            };
          } else {
            this.invalid_cnpj = true;
          }
          this.loadingCnpj = false;
        })
        .catch(() => {
          this.loadingCnpj = false;
          this.invalid_cnpj = true;
        });
    },
    handleUploadFileInsurance(file) {
      this.form.files = file;
      this.$bvModal.hide("add-file-insurance");
      this.$forceUpdate();
    },
    handleAddEvidence() {
      this.$bvModal.show("add-file-insurance");
    },
    handleDeleteFile() {
      this.form.files = null;
    },
    handleSubmitCredentials() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.isLoading = true;
        if (this.isAdd) {
          return this.saveInsurance();
        }
        return this.editInsurance();
      } else {
        this.show_toast({
          message: "Informações do seguro inválidas",
          type: "error",
        });
      }
    },
    getJsonForm() {
      const bodyFormData = {
        ...this.form,
        policy_num: this.$v.form.$model.policy_num.replace(/[-]+/g, ""),
        files: this.form.files?.flatMap(({ id }) => [id]),
        coverage: this.$v.form.$model.coverage
          .replace(/[R$. ]+/g, "")
          .replace(",", "."),
        insurance: this.$v.form.$model.insurance.replace(/[^\d]/g, ""),
        provider: this.is_preview
          ? this.preview_personify?.id
          : this.user?.enterprise?.id,
        type: this.$v.form.$model.type.id,  
      };
      return bodyFormData;
    },
    async saveInsurance() {
      const payload = this.getJsonForm();
      api
        .post("/insurance-provider/", { ...payload })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.$bvModal.hide("add-file-insurance");
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    editInsurance() {
      const data = this.getJsonForm();
      api
        .patch(`/insurance-provider/${this.id}/`, { ...data })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.isLoading = false;
          }
          this.$bvModal.hide("add-file-insurance");
          this.$emit("submited");
        })
        .catch((err) => {
          this.show_toast({
            message: err,
            type: "error",
          });
        });

      this.isLoading = false;
    },

    getEdit() {
      this.get_insurance_provider({ id: this.id }).then(({ data }) => {
        this.form = data;
        this.insurance_data = data.insurance;
        this.form.insurance = this.form.insurance.cnpj;
        this.form.type ={
          id: data.type_id,
          name: data.type,
        }
        this.$nextTick(() => {
          this.form.insurance = this.form.insurance.cnpj;
          this.cnpjNumberMask = "##.###.###/####-##";
        });
      });
    },
  },

  mounted() {
    if (!this.isAdd) {
      this.getEdit();
    }
    this.get_all_type().then(({ data }) => {
      this.typeOptions = data.results;
    });
  },
};
</script>

 
<style scoped lang="scss">
.form-data {
  max-width: 600px;
  margin: 0 auto;
}
</style>